
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.s-database {
  position: relative;

  &__block {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__db-list {
    margin-right: 20px;
  }

  &__field {
    margin-bottom: 16px;
  }

  &__table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__column {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color-grayscale-05;
    padding: 5px;
    cursor: move;
  }

  &__column-name {
    flex-shrink: 0;
    width: 40%;
  }

  &__column-label {
    flex-shrink: 0;
    width: 40%;
  }

  &__actions {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
}

.add-columns-modal {
  &__column-list {
    margin-bottom: 16px;
  }
  &__item {
    margin-bottom: 16px;

    .esmp-select {
      width: 200px;
    }

    .esmp-button,
    .esmp-select {
      flex-shrink: 0;
    }
  }

  &__item-name {
    flex-grow: 1;
    margin-bottom: 16px;
  }

  &__item-settings {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__item-error {
    border: 1px solid $color-red;
    border-radius: $base-border-radius;
  }

  &__item-label {
    width: 100%;
    flex-shrink: 0;
  }

  &__item-filter {
    flex-grow: 1;
  }

  &__item-is-required {
    width: 100%;
    flex-shrink: 0;
  }
}
